import { useDates } from './useDates';

const { getTimestamp } = useDates();

export const useSort = () => {
	const sortArrayByDate = (arr) => {
		return arr.sort((a, b) => {
			return parseInt(getTimestamp(b.Till) - parseInt(getTimestamp(a.Till)));
		});
	};

	return { sortArrayByDate };
};
