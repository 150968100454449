import api from './api';

export default {
	/** Получение списка категорий для персональных пропусков.
	 * @param {string} token - токен, полученный при авторизации
	 * @param {string} eventId - идентификатор мероприятия
	 */
	async getPersonCategoriesForEvent(token, eventId) {
		return api.get(`/GetPersonCategoriesForEvent?token=${token}&eventId=${eventId}`);
	},

	/** Получение списка персональных пропусков.
	 * @param {string} token - токен, полученный при авторизации
	 * @param {string} eventId - идентификатор мероприятия
	 */
	async getPersonalPasses(token, eventId) {
		return api.get(`/GetPersons?token=${token}&eventId=${eventId}`);
	},
};
