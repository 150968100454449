export const useDates = () => {
	// дата приходит в виде string /1234567890 + 0300/

	/** Для получения кода даты
	 * @param {string} date строка даты в виде /Date1234567890 + 0300/. Обрезаются все симфолы, кроме даты в миллисекундах. Строка приводится к типу number.
	 */
	const getTimestamp = (date) => {
		if (date) return parseInt(date.substr(6, 13));
	};

	/** Форматирование кода даты (number) в Date
	 * @param {string} date строка даты в виде /Date1234567890 + 0300/. Обрезаются все симфолы, кроме даты в миллисекундах.
	 */
	const getDate = (date) => {
		if (!date) return '';
		return new Date(getTimestamp(date));
	};

	/** Преобразование даты в формат дд месяц гг
	 * @param {string} date строка даты в виде /Date1234567890 + 0300/. Обрезаются все симфолы, кроме даты в миллисекундах.
	 */
	const transformDate = (date, pattern) => {
		if (!date) return;
		// Если передан такой параметр (true или false), то преобразуется в формат дд.мм.гггг типа string
		if (pattern) {
			const year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();
			if (day.toString().length === 1) day = `0${day}`;
			if (month.toString().length === 1) month = `0${month}`;
			return `${day}.${month}.${year}`;
		}

		const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

		const timestamp = getDate(date);
		const year = timestamp.getFullYear();
		let month = months[timestamp.getMonth()];
		let day = timestamp.getDate();
		if (day.toString().length === 1) day = `0${day}`;
		return `${day} ${month} ${year}`;
	};

	/** Преобразование даты для получения часов и минут
	 * @param {string} date строка даты в виде /Date1234567890 + 0300/. Обрезаются все симфолы, кроме даты в миллисекундах.
	 */
	const getHours = (date) => {
		const timestamp = getDate(date);
		let hours = timestamp.getHours();
		let minutes = timestamp.getMinutes();
		hours = hours < 10 ? '0' + hours : hours;
		minutes = minutes < 10 ? '0' + minutes : minutes;
		return `${hours}:${minutes}`;
	};

	/** Функция считает сколько лет прошло от текущей даты, используется для ограничения ввода даты рождения
	 * @param {number} amountOfYears количество лет для расчета от текущей даты
	 */
	const nYearsFromNow = (amountOfYears) => {
		const today = new Date();
		return new Date(today.setFullYear(today.getFullYear() - amountOfYears));
	};

	return {
		getTimestamp,
		getDate,
		transformDate,
		getHours,
		nYearsFromNow,
	};
};
