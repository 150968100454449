import { createRouter, createWebHistory } from 'vue-router';
import { store } from './store/store';

// Lazy-loading компонентов
const UserAuth = () => import('@/pages/UserAuth.vue');
/* const PasswordReset = () => import('@/pages/PasswordReset.vue'); */
const ExhibitionsPage = () => import('@/pages/ExhibitionsPage.vue');
const ExhibitionPage = () => import('@/pages/ExhibitionPage.vue');
const EditPassPage = () => import('@/pages/EditPassPage.vue');
const ImportPassesPage = () => import('@/pages/ImportPassesPage.vue');
const CreatePassPage = () => import('@/pages/CreatePassPage.vue');
const NotFoundPage = () => import('@/pages/NotFoundPage.vue');
const PassesRegistratePage = () => import('@/pages/PassesRegistratePage.vue');

export const router = createRouter({
	history: createWebHistory(),
	routes: [
		{ path: '/', redirect: '/auth' },
		{
			name: 'auth',
			path: '/auth',
			component: UserAuth,
			meta: { requiresUnauth: true },
		},
		/* {
			name: 'reset',
			path: '/reset',
			component: PasswordReset,
			meta: { requiresUnauth: true },
		}, */
		{
			name: 'dashboard',
			path: '/dashboard',
			component: ExhibitionsPage,
			meta: { requiresAuth: true },
		},
		{
			name: 'exhibition-item',
			path: '/dashboard/:exhId',
			component: ExhibitionPage,
			props: true,
			meta: { requiresAuth: true },
		},
		{
			name: 'edit-pass',
			path: '/dashboard/:exhId/edit/:passId',
			component: EditPassPage,
			props: (route) => ({
				passId: route.params.passId,
				passGroup: route.query.pg,
			}),
			meta: { requiresAuth: true },
		},
		{
			name: 'passes-import',
			path: '/dashboard/:exhId/import',
			component: ImportPassesPage,
			props: true,
			meta: { requiresAuth: true },
		},
		{
			name: 'passes-creation',
			path: '/dashboard/:exhId/create',
			component: CreatePassPage,
			props: (route) => ({
				passGroup: route.query.pg,
			}),
			meta: { requiresAuth: true },
		},
		{
			name: 'registration',
			path: '/registration/:guid',
			component: PassesRegistratePage,
			meta: { requiresAuth: false },
		},
		{ path: '/:notFound(.*)', component: NotFoundPage },
	],
	// Cкролит всегда наверх страницы
	scrollBehavior() {
		return { top: 0 };
	},
});

router.beforeEach(async (to, _, next) => {
	let loginError;
	// Перед каждым переходом между страницами выполнять проверку токена
	try {
		await store.dispatch('auth/tryLogin');
	} catch (error) {
		loginError = error.message;
	}

	const isAuth = store._state.data.auth.isAuth;

	// Если целевой путь требует авторизации, но пользователь не авторизован, выполнить переадресацию на /auth
	if ((to.meta.requiresAuth && !isAuth) || loginError) {
		next({ name: 'auth' });
	}
	// Если целевой путь требует быть НЕавторизованным, но пользователь авторизован, выполнить переадресацию на /dashboard
	else if (to.meta.requiresUnauth && isAuth) {
		next({ name: 'dashboard' });
	}
	// Все остальные случаи при каждом переходе проверять валидность токена. Если валиден - позволять переход на страницу, иначе - переадрессация на /auth
	else {
		next();
	}
});
