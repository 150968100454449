<template>
	<nav class="import__steps">
		<ol class="steps__list">
			<slot></slot>
		</ol>
	</nav>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.steps__list {
	list-style-type: none;
	display: flex;
}
</style>
