import { useRequests } from '../composables/useRequests';
const { fetchData, postData } = useRequests();

export default {
	BASE_URL: 'https://pass-auth.expocentr.ru/ExpPassOfficeService/rest/', //https://pass-auth.expocentr.ru/ExpPassOfficeService/rest/ http://expo.isd.su:5432/ExpPassOfficeService/rest/ http://192.168.9.12:5555/ExpPassOfficeService/rest/

	/**
	 * Метод получения информации из API (как fetch GET, только с первичной обработкой)
	 * @param {*} method метод API
	 */
	async get(method) {
		return await fetchData(`${this.BASE_URL}${method}`);
	},

	/**
	 * Метод отправки информации в бэк (как fetch POST)
	 * @param {*} method метод API
	 */
	async post(method, body) {
		return await postData(`${this.BASE_URL}${method}`, body);
	},

	/**
	 * Метод POST для обращения к локальному бэкенду
	 * @param {string} method метод апи
	 * @param {*} body тело запроса
	 */
	async postLocal(method, body) {
		return await postData(`/api${method}`, body);
	},
};
