import authService from '@/services/authService';
import eventsService from '@/services/eventsService';
import personalAgreementService from '@/services/personalAgreementService';

export default {
	namespaced: 'auth',
	state() {
		return {
			token: null,
			login: '',
			isAuth: false,
			error: null,
			isTermsAccepted: 1,
		};
	},
	mutations: {
		setUser(state, payload) {
			state.token = payload.token;
			state.isAuth = payload.isAuth;
			state.login = payload.login;
		},
		clearAuthData(state) {
			state.token = null;
			state.isAuth = false;
			state.login = '';
			state.isTermsAccepted = 1;
			state.error = null;
			localStorage.removeItem('token');
			localStorage.removeItem('login');
		},
		setError(state, payload) {
			state.error = payload;
		},
		setTermsAcceptance(state, payload) {
			state.isTermsAccepted = payload;
		},
		setToken(state, payload) {
			state.token = payload;
			localStorage.setItem('token', state.token);
		},
	},
	actions: {
		async login(context, payload) {
			try {
				/* При первом логине по умолчанию принятие согласия на обработку данных равно 1 (не принято).
				Происходит получение токена и дальнейшая проверка по нему принял ли пользователь согласие ранее.
				Если условия приняты не были, на интерфейсе появится предложение принять их. После принятия функция login вызывается еще раз,
				но уже не выполняется повторное получение токена и проверка на принятие условий.
				*/
				if (context.state.isTermsAccepted === 1) {
					const response = await authService.getToken(payload.login, payload.password);

					context.commit('setToken', response);

					/* Проверка принято ли согласие на обработку перс данных */
					if (localStorage.getItem('token')) {
						try {
							await context.dispatch('checkTermsAcceptance', context.state.token);
							if (context.state.isTermsAccepted === 1) {
								context.commit('setError', 'Необходимо дать согласие на обработку персональных данных');
								throw new Error(context.state.error);
							}
						} catch (error) {
							throw new Error(context.state.error);
						}
					}
				}

				localStorage.setItem('login', payload.login);

				context.commit('setUser', {
					token: context.state.token,
					isAuth: true,
					login: payload.login,
				});

				context.dispatch('clearError');

				// Для изменения стилей страницы
				document.body.classList.add('auth');
			} catch (error) {
				const e = error.includes('неверно имя пользователя/пароль; вход в систему запрещается')
					? 'Неверное имя пользователя или пароль, повторите попытку входа'
					: 'Произошла непредвиденная ошибка. Попробуйте позже или свяжитесь с администратором системы';

				context.commit('setError', e);
				throw new Error(context.state.error);
			}
		},
		async tryLogin(context) {
			try {
				const token = localStorage.getItem('token');
				const login = localStorage.getItem('login');

				// Если токен или логин не найдены в localStorage, то пользователь не был авторизован
				if (!token || !login) {
					context.commit('clearAuthData');
					document.body.classList.remove('auth');
					return;
				}

				context.commit('setUser', {
					token: token,
					isAuth: true,
					login: login,
				});

				// Проверка валидности токена
				await eventsService.getEvents(token);

				document.body.classList.add('auth');
			} catch (error) {
				context.commit('setError', 'Произошла непредвиденная ошибка. Попробуйте позже или свяжитесь с администратором системы');
			}
		},
		setUnauth(context) {
			context.dispatch('clearAuthData');
			context.commit('setError', 'Недостаточно прав доступа. Авторизуйтесь и повторите попытку');
			document.body.classList.remove('auth');
		},
		clearAuthData(context) {
			context.commit('clearAuthData');
		},
		clearError(context) {
			context.commit('setError', null);
		},
		/** Проверка приняты ли условия обработки персональных данных */
		async checkTermsAcceptance(context, payload) {
			try {
				const result = await personalAgreementService.checkTermsAcceptance(payload);
				// todo: проверить
				context.dispatch('changeTermsAcceptance', result);
			} catch (error) {
				throw new Error(error);
			}
		},
		changeTermsAcceptance(context, payload) {
			context.commit('setTermsAcceptance', payload);
		},
	},
};
