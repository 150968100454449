import { createStore } from 'vuex';
import authModule from './auth/authModule';
import autoPassesModule from './passes/autoPassesModule';
import exhibitionsModule from './exhibitions/exhibitionsModule';
import personalPassesModule from './passes/personalPassesModule';
import importModule from './import/importModule';

export const store = createStore({
	modules: {
		auth: authModule,
		exhibitions: exhibitionsModule,
		autoPasses: autoPassesModule,
		personalPasses: personalPassesModule,
		import: importModule,
	},
});
