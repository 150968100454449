<template>
	<header :class="{ loggedIn: isAuth }">
		<div class="wrapper">
			<div class="logo-wrapper">
				<RouterLink :to="{ name: 'dashboard' }">
					<img class="logo" src="../../assets/img/logo.svg" alt="Экспоцентр логотип" />
				</RouterLink>
			</div>
			<!-- <RouterLink :to="{ path: '/' }" class="lk"
				>Личный Кабинет</RouterLink
			>
			<div v-if="isAuth" class="exhibitions" @click="toggleMenu">
				<base-burger-menu
					:isMenuOpened="isExhibitShown"
				></base-burger-menu>
				<RouterLink :to="{ path: '/' }">Мои пропуска</RouterLink>
			</div> -->
		</div>
		<nav>
			<ul>
				<!-- <li>
					<RouterLink
						:to="{ path: '/' }"
						class="element element__support-wrapper"
					>
						<div class="icon icon__support">
							<font-awesome-icon
								icon="fa-regular fa-circle-question"
								size="lg"
								color="#9cdbf8"
							/>
						</div>
						<p>Поддержка</p>
					</RouterLink>
				</li> -->
				<li id="menu-wrapper" v-if="isAuth">
					<div @click="toggle" class="element element__profile-wrapper">
						<div class="icon icon__profile">
							<font-awesome-icon icon="fa-regular fa-user" size="lg" color="#9cdbf8" />
						</div>
						<p id="login">{{ login }}</p>
						<Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
						<div class="icon icon__angle-down">
							<font-awesome-icon icon="fa-solid fa-angle-down" size="lg" color="#9cdbf8" />
						</div>
					</div>
				</li>
				<!-- <li v-if="isAuth" class="element element__notifications">
					<div class="icon icon__notifications">
						<font-awesome-icon
							class="icon__notifications_bell"
							icon="fa-regular fa-bell"
							color="#9cdbf8"
						/>
					</div>
					<div class="notifications__counter-wrapper">
						<div class="notifications__counter">5</div>
					</div>
				</li> -->
				<!-- <li class="element element__language">
					<RouterLink :to="{ path: '/' }">En</RouterLink>
				</li> -->
			</ul>
		</nav>
	</header>
</template>

<script setup>
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const router = useRouter();
/* const isExhibitShown = ref(false); */
const items = reactive([
	{
		label: 'Выйти',
		icon: 'pi pi-sign-out',
		command: () => {
			logout();
		},
	},
]);

/** Выпадающее меню */
const menu = ref(null);

/** Функция открытия/закрытия выпадающего меню */
const toggle = (e) => {
	menu.value.toggle(e);
};

const logout = () => {
	store.dispatch('auth/clearAuthData');
	document.body.classList.remove('auth');
	router.replace({ name: 'auth' });
};

const isAuth = computed(() => store.state.auth.isAuth);
const login = computed(() => store.state.auth.login);
</script>

<style scoped lang="scss">
header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 7vh;
	font-size: 1.7rem;
	padding: 0 5%;
}

.loggedIn {
	background-color: #023f75;
	margin-bottom: 5rem;
}

a {
	color: #9cdbf8;
	text-decoration: none;
}

ul {
	display: flex;
	align-items: center;
}

li {
	list-style: none;
}

li:not(:last-of-type) {
	margin-right: 3rem;
}

/* .lk {
	border-left: 2px solid #9cdbf8;
	padding: 0 2rem;
	color: #fff;
}

.lk:hover {
	text-shadow: 0 0 3px #9cdbf8;
}
 */
.icon {
	margin-right: 1rem;
}

.icon__angle-down {
	margin-left: 1rem;
}

.wrapper {
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	max-height: 23px;
}
/* .element__language {
	a {
		color: #9cdbf8;
		font-size: 1.4rem;
	}
} */

/* .element__language:hover {
	text-decoration: underline;
} */

/* .exhibitions {
	display: flex;
	align-items: center;
}
.notifications__counter-wrapper {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: rgb(255, 115, 0);
	position: absolute;
	top: -4px;
	left: 18px;
}

.notifications__counter {
	position: absolute;
	top: 50%;
	left: 45%;
	transform: translate(-50%, -50%);
	color: #fff;
}
 */
.element__profile-wrapper
/* .element__support-wrapper  */ {
	display: flex;
	align-items: center;
}

/* .element__notifications {
	position: relative;
	cursor: pointer;
}

.icon__notifications {
	margin-right: 0;
	padding: 0 3px;
}

.icon__notifications_bell {
	font-size: 2.5rem;
}
 */
.element
/* .exhibitions  */ {
	padding: 5px;
	color: #9cdbf8;
	cursor: pointer;
}
.element:hover,
.exhibitions:hover {
	background-color: #077bb1;
	border-radius: 5px;
}

.logo {
	transition:
		box-shadow 0.3s ease-in-out,
		border-radius 0.3s ease-in-out;
	padding: 1rem;
}

.logo:hover {
	box-shadow: 0 0 10px #9cdbf8;
	border-radius: 3px;
}
</style>
