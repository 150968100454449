import api from './api';

export default {
	/** Получение списка мероприятий. Также используется для проверки валидности токена
	 * @param {string} token - токен, полученный при авторизации
	 */
	async getEvents(token) {
		return api.get(`/GetEvents?token=${token}&includeWithoutSeances=true`);
	},

	/** Получение одного конкретного мероприятия
	 * @param {string} token - токен, полученный при авторизации
	 * @param {string} eventId - идентификатор мероприятия
	 */
	async getEvent(token, eventId) {
		return api.get(`/GetEvents?token=${token}&includeWithoutSeances=true&events=${eventId}`);
	},
};
