import { createApp, defineAsyncComponent } from 'vue';
import App from './App.vue';
import { router } from './router';
import BaseCard from './components/UI/BaseCard.vue';
import BaseButton from './components/UI/BaseButton.vue';
import BaseSteps from './components/UI/BaseSteps.vue';
import BaseStep from './components/UI/BaseStep.vue';
import { store } from './store/store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faCircleQuestion as faCircleQuestionSolid } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import BaseLoader from './components/UI/BaseLoader.vue';
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Tooltip from 'primevue/tooltip';
import 'primevue/resources/primevue.min.css';
import '@/theme.css';
import 'primeicons/primeicons.css';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import FileUpload from 'primevue/fileupload';
import Badge from 'primevue/badge';
import Checkbox from 'primevue/checkbox';
import Password from 'primevue/password';
import BaseBackButton from './components/UI/BaseBackButton.vue';

//Константы
export const TERMS_URL = 'https://www.expocentr.ru/upload/docs/Expocentre_personal_data_processing_policy_ru.pdf';
// Иконки FontAwesome
library.add(faBell);
library.add(faCircleQuestion);
library.add(faUser);
library.add(faAngleDown);
library.add(faCircleQuestionSolid);
library.add(faArrowLeft);

const app = createApp(App);

// Lazy-loading компонента BaseDialog
const BaseDialog = defineAsyncComponent(() => import('./components/UI/BaseDialog.vue'));

// Импорт глобальных компонентов
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('base-card', BaseCard);
app.component('base-button', BaseButton);
app.component('base-dialog', BaseDialog);
app.component('base-loader', BaseLoader);
app.component('base-steps', BaseSteps);
app.component('base-back-button', BaseBackButton);
app.component('base-step', BaseStep);
app.component('DataTable', DataTable);
// eslint-disable-next-line
app.component('Calendar', Calendar);
// eslint-disable-next-line
app.component('Column', Column);
// eslint-disable-next-line
app.component('Button', Button);
// eslint-disable-next-line
app.component('Menu', Menu);
// eslint-disable-next-line
app.component('Dropdown', Dropdown);
app.component('InputText', InputText);
// eslint-disable-next-line
app.component('Textarea', Textarea);
app.component('FileUpload', FileUpload);
// eslint-disable-next-line
app.component('Badge', Badge);
// eslint-disable-next-line
app.component('Checkbox', Checkbox);
// eslint-disable-next-line
app.component('Password', Password);
// eslint-disable-next-line
app.directive('tooltip', Tooltip);

app.use(router);
app.use(store);
app.use(PrimeVue);

app.mount('#app');
