<template>
	<TheHeader />
	<base-dialog title="Ошибка" @close-dialog="closeDialog" :show="!!authError">
		<p>{{ authError }}</p>
	</base-dialog>
	<RouterView v-if="!authError" v-slot="{ Component }">
		<template v-if="Component">
			<Suspense>
				<component :is="Component"></component>
				<template #fallback>
					<base-loader isPage v-if="isLoading"></base-loader>
				</template>
			</Suspense>
		</template>
	</RouterView>
</template>

<script>
import { mapState } from 'vuex';
import TheHeader from './components/layout/TheHeader.vue';

export default {
	data() {
		return {
			isLoading: false,
			authError: null,
		};
	},
	computed: {
		...mapState('auth', ['isAuth']),
		...mapState('auth', ['error']),
	},
	async created() {
		try {
			this.isLoading = true;
			await this.$store.dispatch('auth/tryLogin');
		} catch (error) {
			this.authError = this.error;
		} finally {
			this.isLoading = false;
		}
	},
	methods: {
		closeDialog() {
			this.authError = null;
		},
	},
	unmounted() {
		if (this.isAuth) {
			document.body.classList.remove('auth');
		}
	},
	components: { TheHeader },
};
</script>

<style lang="scss">
@import url(https://fonts.googleapis.com/css?family=Roboto:regular,700);

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 62.5%;
	font-family: 'Roboto', 'Arial', sans-serif;
	color: black;
}

body {
	background: no-repeat url('./assets/img/background.svg');
	min-height: 100vh;
	background-position: right bottom;
	background-size: cover;
}

.container {
	max-width: 90%;
	margin: 0 auto;
}

.auth {
	background: #fff;
	min-height: 100vh;
}

.p-dropdown-panel {
	z-index: 1000;
	ul li {
		font-size: 1.5rem;
	}
}

.p-menu {
	width: 17rem !important;

	ul li {
		font-size: 1.4rem;
	}

	.pi {
		font-size: 1.3rem;
	}
}

.p-datepicker {
	z-index: 100;
}

.p-datepicker,
.p-datepicker-month,
.p-link,
.p-inputtext {
	font-size: 1.5rem !important;

	.p-datepicker-calendar {
		font-size: 1.5rem !important;

		span {
			width: 3rem;
			height: 3rem;
		}
	}
}

.p-tooltip {
	z-index: 100;
}
</style>
