import { useDates } from '@/composables/useDates';

const { transformDate } = useDates();

// Cписок и функция созданы для определения значения цели визита на кириллице, так как апи возвращает значение на латинице
export default {
	namespaced: 'autoPasses',
	state() {
		return {
			code: '',
			message: '',
			passes: {},
			selectedPassesType: {},
			importToken: '',
			selectedPasses: [],
		};
	},
	mutations: {
		setCodeAndMessage(state, payload) {
			state.code = payload.Code;
			state.message = payload.Message;
			state.passes = payload.Result.Persons.map((pass) => {
				return { ...pass.Person, ...pass.Result };
			});
		},
		setPersonalPasses(state, payload) {
			state.importToken = payload.Result.Token;
			state.passes = payload.Result.Persons.map((pass) => {
				return {
					...pass.Person,
					...pass.Result,
					name: `${pass.Person.Surname} ${pass.Person.FirstName} ${pass.Person.SecondName}`,
					birthDate: transformDate(pass.Person.BirthDate),
					validFrom: transformDate(pass.Person.ValidFrom),
					validTill: transformDate(pass.Person.ValidTill),
				};
			});
		},
		setAutoPasses(state, payload) {
			state.importToken = payload.Result.Token;
			state.passes = payload.Result.AutoPasses.map((pass) => {
				return {
					...pass.AutoPass,
					...pass.Result,
					name: `${pass.AutoPass.Surname} ${pass.AutoPass.FirstName} ${pass.AutoPass.SecondName}`,
					birthDate: transformDate(pass.AutoPass.BirthDate),
					validFrom: transformDate(pass.AutoPass.ValidFrom),
				};
			});
		},
		setSelectedPassesType(state, payload) {
			state.selectedPassesType = payload;
		},
		setSelectedPasses(state, payload) {
			state.selectedPasses = payload;
		},
	},
	actions: {
		setImportCheckResult(context, payload) {
			context.commit('setCodeAndMessage', payload);

			context.state.selectedPassesType.id === 'DailyAutopassesImportClass'
				? context.commit('setAutoPasses', payload)
				: context.commit('setPersonalPasses', payload);
		},
		setSelectedPassesType(context, payload) {
			context.commit('setSelectedPassesType', payload);
		},
		setSelectedPasses(context, payload) {
			context.commit('setSelectedPasses', payload);
		},
	},
};
