import { useDates } from '@/composables/useDates';
import { useSort } from '@/composables/useSort';
import eventsService from '@/services/eventsService';
import personalPassesService from '@/services/personalPassesService';
import autoPassesService from '@/services/autoPassesService';

const { getDate, getTimestamp } = useDates();
const { sortArrayByDate } = useSort();

export default {
	namespaced: 'exhibitions',
	state() {
		return {
			exhibitions: [],
			currentEvents: [],
			/* pastEvents: [], */
			oneExhibition: {},
			isActualDate: false, // Если мероприятие еще не прошло, значит, дата актуальна и можно создавать пропуск
			personCategories: [],
			autoCategories: [],
		};
	},
	mutations: {
		setCurrentExhibitions(state, payload) {
			state.currentEvents = payload;
		},
		/* setPastExhibitions(state, payload) {
			state.pastEvents = payload;
		}, */
		setExhibitions(state, payload) {
			state.exhibitions = payload;
		},
		setOneExhibition(state, payload) {
			state.oneExhibition = payload;
		},
		// Проверка даты мероприятия для блокировки возможности создания пропуска, если мероприятие уже прошло
		setIsActualDate(state) {
			if (!state.oneExhibition.Till || state.oneExhibition.Till === '') {
				state.isActualDate = false;
			} else {
				const now = new Date();
				const tillDate = getDate(state.oneExhibition.Till);
				state.isActualDate = now < tillDate;
			}
		},
		setEventCategories(state, payload) {
			state.personCategories = payload.personCategories;
			state.autoCategories = payload.autoCategories;
		},
	},
	actions: {
		async getExhibitions(context) {
			const token = context.rootState.auth.token;
			try {
				const response = await eventsService.getEvents(token);

				// Сохранение общего списка выставок
				context.commit('setExhibitions', response);
				const today = new Date();
				let currentEvents = [];
				let pastEvents = [];

				// Разбиение выставок на два списка: текущие и прошедшие
				response.forEach((event) => {
					if (parseInt(getTimestamp(event.Till)) > today) {
						currentEvents.push(event);
					} else {
						pastEvents.push(event);
					}
				});

				// Сохранение отсортированных по дате и актуальности мероприятий в стейт
				context.commit('setCurrentExhibitions', sortArrayByDate(currentEvents));
			} catch (error) {
				if (error.includes('Токен не найден или просрочен')) {
					throw new Error('Недостаточно прав доступа. Авторизуйтесь и повторите попытку');
				} else {
					throw new Error('Не удалось получить список мероприятий, повторите попытку позже или обратитесь к администратору');
				}
			}
		},
		async getOneExhibition(context, exhId) {
			try {
				let exhibition = context.state.exhibitions.find((el) => el.EventId == exhId);
				if (!exhibition) {
					const token = context.rootState.auth.token;

					const response = await eventsService.getEvent(token, exhId);

					exhibition = response.find((el) => el.EventId == exhId);

					if (!exhibition) {
						throw new Error();
					}
				}
				context.commit('setOneExhibition', exhibition);
				await context.dispatch('getEventCategories');
			} catch (e) {
				throw new Error(e.message);
			}
		},
		async getEventCategories(context) {
			const token = context.rootState.auth.token;
			const eventId = context.state.oneExhibition.EventId;
			try {
				const personCategories = await personalPassesService.getPersonCategoriesForEvent(token, eventId);
				const autoCategories = await autoPassesService.getAutopassCategoriesForEvent(token, eventId);

				context.commit('setEventCategories', {
					personCategories: personCategories.map((category) => {
						return { name: category, canCreateRequest: 0 };
					}),
					autoCategories: autoCategories.map((category) => {
						return { name: category, canCreateRequest: 0 };
					}),
				});
			} catch (error) {
				if (error.includes('"<?xml vers"... is not valid JSON')) {
					throw new Error(
						'Не удалось получить категории персональных пропусков для выбранного мероприятия. Попробуйте позже или обратитесь к администратору.'
					);
				} else {
					throw new Error(error);
				}
			}
		},
		setIsActualDate(context) {
			context.commit('setIsActualDate');
		},
	},
};
