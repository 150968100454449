export const useRequests = () => {
	/**
	 * Получения информациия с сервера
	 * @param {string } url адрес апи
	 */
	const fetchData = async (url) => {
		try {
			const response = await fetch(url);
			const responseData = await response.json();
			if (!responseData.Result && responseData.Result !== 0) {
				const error = new Error(responseData.Message);
				throw error;
			}
			return responseData.Result;
		} catch (error) {
			throw error.message;
		}
	};

	/**
	 * Отправка информации на сервер
	 * @param {string} url адрес апи
	 * @param {*} queryBody тело запроса
	 */
	const postData = async (url, queryBody) => {
		try {
			const result = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				body: JSON.stringify(queryBody),
			});
			return result;
		} catch (error) {
			throw error.message;
		}
	};

	return {
		fetchData,
		postData,
	};
};
