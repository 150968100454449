import { useDates } from '@/composables/useDates';
import personalPassesService from '@/services/personalPassesService';

const { transformDate, getDate } = useDates();

export default {
	namespaced: 'personalPasses',
	state() {
		return {
			passes: [],
			selectedPasses: [],
		};
	},
	mutations: {
		setPasses(state, payload) {
			state.passes = payload;
		},
		setSelectedPasses(state, payload) {
			state.selectedPasses = payload;
		},
	},
	actions: {
		async getPasses(context, payload) {
			const token = context.rootState.auth.token;

			try {
				const response = await personalPassesService.getPersonalPasses(token, payload);
				const passes = response.map((pass) => {
					return {
						...pass,
						birthDate: transformDate(getDate(pass.BirthDate), true),
						validFrom: transformDate(getDate(pass.ValidFrom), true),
						validTill: transformDate(getDate(pass.ValidTill), true),
						bearerType: pass.BearerType === 1 ? 'Баркод' : 'Mifare',
						name: `${pass.Surname} ${pass.FirstName} ${pass.SecondName}`,
					};
				});
				context.commit('setPasses', passes);
			} catch (error) {
				if (error.includes('"<?xml vers"... is not valid JSON')) {
					throw new Error(
						'Не удалось получить персональные пропуска для выбранного мероприятия. Попробуйте позже или обратитесь к администратору.'
					);
				} else {
					throw new Error(error);
				}
			}
		},
		setSelectedPasses(context, payload) {
			context.commit('setSelectedPasses', payload);
		},
	},
};
