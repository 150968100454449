import api from './api';

export default {
	/** Получение списка категорий для автомобильных пропусков.
	 * @param {string} token токен пользователя
	 * @param {string} eventId идентификатор мероприятия
	 */
	async getAutopassCategoriesForEvent(token, eventId) {
		return api.get(`/GetAutopassCategoriesForEvent?token=${token}&eventId=${eventId}`);
	},

	/** Получение списка автомобильных пропусков.
	 * @param {string} token токен пользователя
	 * @param {string} eventId идентификатор мероприятия
	 */
	async getAutoPasses(token, eventId) {
		return api.get(`/GetAutoPasses?token=${token}&eventId=${eventId}`);
	},

	/**
	 * Получение КПП (для выпадающего списка на формах)
	 * @param {string} token токен пользователя
	 */
	async getKppList(token) {
		return api.get(`/GetKpp?token=${token}`);
	},

	/** Получение списка парковок для автопропуска
	 * @param {string} token токен пользователя
	 */
	async getParkings(token) {
		return api.get(`/GetParkings?token=${token}`);
	},
};
