import api from './api';

export default {
	/** Проверка приняты ли условия обработки персональных данных
	 * @param {string} token - токен, полученный при авторизации
	 */
	async checkTermsAcceptance(token) {
		return api.get(`/CheckPersonalDateAgreementRequired?token=${token}`);
	},

	/** Отправка подтверждения согласия на обработку персональных данных
	 * @param {string} token - токен, полученный при авторизации
	 */
	async confirmPersonalDataAgreement(token) {
		return api.get(`/ConfirmPersonalDataAgreement?token=${token}`);
	},
};
