import { useDates } from '@/composables/useDates';
import autoPassesService from '@/services/autoPassesService';

// Cписок и функция созданы для определения значения цели визита на кириллице, так как апи возвращает значение на латинице
const purposes = [
	{ id: 'mounting', value: 'Монтаж' },
	{ id: 'demounting', value: 'Демонтаж' },
	{ id: 'transportation', value: 'Довоз во время выставки' },
	{ id: 'documents_delivery', value: 'Доставка документов' },
	{ id: 'negotiation', value: 'Переговоры' },
	{ id: 'mass_media', value: 'СМИ' },
];

const getCyrillicPurposeName = (purposeId) => {
	if (purposeId && purposeId !== '-') return purposes.find((purpose) => purpose.id === purposeId).value;
};

const { transformDate, getDate } = useDates();
export default {
	namespaced: 'autoPasses',
	state() {
		return {
			passes: [],
			selectedPasses: [],
		};
	},
	mutations: {
		setPasses(state, payload) {
			state.passes = payload;
		},
		setSelectedPasses(state, payload) {
			state.selectedPasses = payload;
		},
	},
	actions: {
		async getPasses(context, payload) {
			const token = context.rootState.auth.token;

			try {
				const response = await autoPassesService.getAutoPasses(token, payload);

				const passes = response.map((pass) => {
					return {
						...pass,
						birthDate: transformDate(getDate(pass.BirthDate), true),
						validFrom: transformDate(getDate(pass.ValidFrom), true),
						validTill: transformDate(getDate(pass.ValidTill), true),
						purpose: getCyrillicPurposeName(pass.Purpose),
						bearerType: pass.BearerType === 1 ? 'Баркод' : 'Mifare',
						name: `${pass.Surname} ${pass.FirstName} ${pass.SecondName}`,
					};
				});
				context.commit('setPasses', passes);
			} catch (error) {
				if (error.includes('"<?xml vers"... is not valid JSON')) {
					throw new Error(
						'Произошла непредвиденная ошибка при получении списка пропусков. Обратитесь к администратору или попробуйте позже.'
					);
				} else {
					throw new Error(error);
				}
			}
		},
		setSelectedPasses(context, payload) {
			context.commit('setSelectedPasses', payload);
		},
	},
};
