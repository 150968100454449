import api from './api';

export default {
	/** Получение токена
	 * @param {string} login - логин, введенный пользователем
	 * @param {string} password - пароль, введенный пользователем
	 */
	async getToken(login, password) {
		return api.get(`/GetToken?login=${login}&password=${password}`);
	},
};
